<template>
  <div>
    <!-- 搜索栏 -->
    <search-bar class="search-bar" v-model="keyword" @search="handleSearch"></search-bar>

    <!-- 列表 -->
    <div class="list">
      <!-- 列表项 -->
      <div class="list-item flex-row-default-center" v-for="(item,index) in list" :key="index" @click="handleSwitchPage(item.memberID)">
        <!-- 图片 -->
        <img :src="item.logo !== '' ? item.logo : require('@/assets/images/img_fail.png')" />
        <!-- 内容 -->
        <div class="content flex-column-around-default">
          <!-- 企业名 -->
          <div class="company-name one-line">{{ item.companyName }}</div>
          <!-- 企业简介 -->
          <div ref="company-info" class="company-info three-lines">
            加入时间：{{ item.confirmedAt.substring(0, 10) || item.createdAt.substring(0, 10) }}
          </div>
        </div>
      </div>
    </div>

    <!-- 底标 -->
    <div class="no-more" v-if="isEnd">没有更多内容了</div>
  </div>
</template>

<script>
import page from '@/mixins/page'
import SearchBar from '@/components/SearchBar'

export default {
  name: 'MemberList',

  data () {
    return {
      list: [],
      keyword: '',
      currentPage: 1,
      pageSize: 8,
      isEnd: false
    }
  },

  components: { SearchBar },

  mixins: [page],

  created () {
    this.pageSize = Math.ceil((this.getWindowHeight() - 30 - 32) / 100)
    this.getData()
  },

  beforeRouteLeave (to, from, next) {
    from.meta.keepAlive = false
    next()
  },

  methods: {
    // 获取数据
    getData () {
      this.api.getAllMember({ currentPage: this.currentPage, pageSize: this.pageSize, keyword: this.keyword }).then(res => {
        if (res.data.length === 0 || res.data.length < this.pageSize) this.isEnd = true
        this.list = [...this.list, ...res.data]
      }).catch(err => {
        if (err.status === 401) this.$store.commit('addLoginCallBack', () => { this.getData() })
      })
    },
    // 查看详情
    handleSwitchPage (id) {
      this.$router.push({ path: '/member-detail', query: { id } })
    },
    // 触底函数
    hitBottom () {
      if (this.isEnd) return
      this.currentPage++
      this.getData()
    },
    // 搜索
    handleSearch () {
      this.initPage()
      this.getData()
    }
  }
}
</script>

<style scoped>
.search-bar {
  margin: 15px;
}
.list {
  margin: 15px;
}
.list-item {
  border: 1px solid #dddddd;
  background: #fff;
  border-radius: 8px;
  box-shadow: 2px 2px 2px #eeeeee;
}
.list-item + .list-item {
  margin-top: 10px;
}
.list-item > img {
  width: 70px;
  height: 70px;
  border-radius: 5px 0 0 5px;
  object-fit: cover;
  margin-right: 15px;
}
.content {
  width: calc(100vw - 70px - 30px - 30px - 10px);
  height: 60px;
}
.company-name {
  font-size: 14px;
}
.company-info {
  font-size: 12px;
  color: #999999;
}
</style>
